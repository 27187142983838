import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import CardHomePage from "../components/card-home-page";
import Hero from "../components/hero";
import ReviewSection from "../components/review-section";
import Button from "react-bootstrap/Button";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { BgImage } from "gbimage-bridge";
import ContactForm from "../components/contactForm";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import PhotoLeft from "../components/photo-left";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import uuid4 from "uuid4";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "home-page" }) {
				homePageFieldGroups {
					bannerSection {
						heading
						subHeading
						cta {
							target
							title
							url
						}
						notice
						backgroundImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						backgroundImage2 {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					serviceSection {
						heading
						subHeading
						description
						cta {
							target
							title
							url
						}
						services {
							title
							description
							image {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
										}
									}
								}
							}
							serviceLink {
								title
								target
								url
							}
						}
					}
					ctaWithImageSection {
						heading
						description
						cta {
							title
							target
							url
						}
						backgroundImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						overlayImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					twoColumnSection {
						content
						image {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					teamsSection {
						description
						heading
						link {
							title
							target
							url
						}
						websiteLogo {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						teams {
							nodes {
								... on WpTeamMember {
									id
									slug
									teamMemberFields {
										imageSquare {
											node {
												altText
												localFile {
													childImageSharp {
														gatsbyImageData(
															formats: [AUTO, WEBP]
															quality: 100
															transformOptions: {
																cropFocus: CENTER
																fit: CONTAIN
															}
															layout: CONSTRAINED
															placeholder: BLURRED
														)
													}
												}
											}
										}
									}
								}
							}
						}
					}
					textOnlySection {
						heading
						content
					}
					twoColumnHeadingSection {
						heading
						content
						image {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					reviewsSection {
						heading
						subHeading
					}
					homeContactSection {
						description
						heading
					}
					twoColumnLeftImageSection {
						heading
						content
						image {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
				}
				seoFieldGroups {
					metaTitle
					metaDescription
					localBusinessSchema
					openGraphDescription
					openGraphTitle
					productSchema
					image {
						node {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFieldGroups, homePageFieldGroups },
	} = data;

	const {
		bannerSection,
		serviceSection,
		ctaWithImageSection,
		twoColumnSection,
		teamsSection,
		textOnlySection,
		twoColumnHeadingSection,
		reviewsSection,
		homeContactSection,
		twoColumnLeftImageSection,
	} = homePageFieldGroups;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<LocalBusinessSchema />
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<Layout>
				{bannerSection &&
					!checkPropertiesForNull(bannerSection, ["heading"]) && (
						<Hero
							image1={
								bannerSection?.backgroundImage?.node?.localFile.childImageSharp
									.gatsbyImageData
							}
							image2={
								bannerSection?.backgroundImage2?.node?.localFile.childImageSharp
									.gatsbyImageData
							}
							title={bannerSection?.heading}
							subHeading={bannerSection?.subHeading}
							ctaButton={bannerSection?.cta}
							notice={bannerSection?.notice}
						/>
					)}
				{serviceSection &&
					!checkPropertiesForNull(serviceSection, ["heading"]) && (
						<section className=" py-lg-7 py-5">
							<Container>
								<Row>
									<Col className="text-center">
										<p className="text-accent jost-bold fs-6">
											{serviceSection?.subHeading}
										</p>
										<h2 className=" display-4 pb-4">
											{serviceSection?.heading}
										</h2>
										<SafeHtmlParser htmlContent={serviceSection?.description} />
									</Col>
								</Row>
								{serviceSection &&
									!checkPropertiesForNull(serviceSection, ["services"]) && (
										<Row className="pt-lg-8 pt-5 justify-content-center">
											{serviceSection.services.map((item) => (
												<CardHomePage
													key={uuid4()}
													imageHeight="200px"
													headingHeight="83px"
													image={
														item.image.node?.localFile.childImageSharp
															.gatsbyImageData
													}
													imgAlt={item.image.node?.altText}
													title={item.title}
													text={
														<SafeHtmlParser htmlContent={item.description} />
													}
													bgColour="bg-primary"
													url={item.serviceLink?.url}
													buttonText={item.serviceLink?.title}
												/>
											))}
										</Row>
									)}
								{serviceSection.cta && serviceSection.cta.url && (
									<Row>
										<Col className="text-center pt-5">
											{" "}
											<Button
												className="text-white py-3 px-5 w-100 w-md-auto"
												variant="accent"
												href={serviceSection.cta.url}
												target={serviceSection.cta.target ?? "_blank"}
												rel="noreferrer"
											>
												{serviceSection.cta.title}
											</Button>
										</Col>
									</Row>
								)}
							</Container>
						</section>
					)}
				{ctaWithImageSection &&
					!checkPropertiesForNull(ctaWithImageSection, ["heading"]) && (
						<section>
							<BgImage
								className="w-100 position-relative "
								image={
									ctaWithImageSection?.backgroundImage.node?.localFile
										.childImageSharp.gatsbyImageData
								}
								alt={ctaWithImageSection?.backgroundImage.node?.altText}
							>
								<div
									style={{ opacity: 0.2, zIndex: 1 }}
									className="bg-black position-absolute w-100 h-100 top-0 start-0"
								></div>
								<Container style={{ zIndex: 2 }} className="position-relative">
									<Row className="py-5 py-lg-0">
										<Col
											md={{ span: 4, order: "last" }}
											className="text-lg-end pb-5 pb-md-0 "
										>
											<GatsbyImage
												className="w-100"
												image={
													ctaWithImageSection.overlayImage?.node?.localFile
														.childImageSharp.gatsbyImageData
												}
												alt={ctaWithImageSection.overlayImage?.node?.altText}
											/>
										</Col>
										<Col className="py-lg-10">
											<Link
												to={ctaWithImageSection?.cta?.url}
												className="white-link d-inline-block"
											>
												<h2 className=" text-start text-white fs-1 pb-4 d-inline-block ">
													{ctaWithImageSection?.heading}
												</h2>
											</Link>
											<div className="content">
												<SafeHtmlParser
													htmlContent={ctaWithImageSection?.description}
												/>
											</div>
											{ctaWithImageSection?.cta &&
												ctaWithImageSection?.cta?.url && (
													<Button
														as={Link}
														to={ctaWithImageSection?.cta?.url}
														variant="accent"
														className="btn btn-primary w-100 w-md-auto text-white px-5 py-2 mt-3"
													>
														{ctaWithImageSection?.cta?.title}
													</Button>
												)}
										</Col>
									</Row>
								</Container>
							</BgImage>
						</section>
					)}
				{twoColumnSection &&
					!checkPropertiesForNull(twoColumnSection, ["content"]) && (
						<section className="py-5 py-lg-7">
							<Container>
								<Row className="g-5">
									<Col lg={{ span: 5, order: "last" }}>
										<GatsbyImage
											className=""
											image={
												twoColumnSection.image.node?.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={twoColumnSection.image.node?.altText}
										/>
									</Col>{" "}
									<Col lg={7}>
										<SafeHtmlParser htmlContent={twoColumnSection?.content} />
									</Col>
								</Row>
							</Container>
						</section>
					)}
				{teamsSection && !checkPropertiesForNull(teamsSection, ["teams"]) && (
					<section className="bg-primary ">
						<Container>
							<Row className="align-items-center text-center text-lg-start">
								<Col className="py-5 py-lg-0 pe-lg-7" lg={6}>
									<Link to={teamsSection?.link.url} className="white-link">
										<h2 className="display-4 pb-4 ">
											<SafeHtmlParser htmlContent={teamsSection?.heading} />
										</h2>
										<div className="content">
											<SafeHtmlParser htmlContent={teamsSection?.description} />
										</div>
									</Link>
								</Col>
								{teamsSection.teams && teamsSection.teams.nodes.length > 0 && (
									<Col lg={{ span: 6 }}>
										<Row className=" g-3 py-lg-5 pb-5 ">
											{teamsSection.teams.nodes.map((item, i) => {
												return (
													<>
														{i === 4 && (
															<Col xs={6} md={4}>
																<Link to="/">
																	<GatsbyImage
																		image={
																			teamsSection.websiteLogo.node?.localFile
																				.childImageSharp.gatsbyImageData
																		}
																		alt={teamsSection.websiteLogo.node?.altText}
																	/>
																</Link>
															</Col>
														)}
														<Col xs={6} md={4}>
															<Link to={`/biography/${item.slug}`}>
																<div className="bg-secondary">
																	<GatsbyImage
																		image={
																			item.teamMemberFields.imageSquare.node
																				?.localFile.childImageSharp
																				.gatsbyImageData
																		}
																		alt={
																			item.teamMemberFields.imageSquare.node
																				?.altText
																		}
																	/>
																</div>
															</Link>
														</Col>
													</>
												);
											})}
										</Row>
									</Col>
								)}
							</Row>
						</Container>
					</section>
				)}
				{textOnlySection &&
					!checkPropertiesForNull(textOnlySection, ["heading"]) && (
						<section className="py-5 py-lg-7">
							<Container>
								<Row>
									<Col className="text-center">
										<h2 className="  fs-1 mb-5 ">{textOnlySection?.heading}</h2>
										<SafeHtmlParser htmlContent={textOnlySection?.content} />
									</Col>
								</Row>
							</Container>
						</section>
					)}
				{twoColumnHeadingSection &&
					!checkPropertiesForNull(twoColumnHeadingSection, ["heading"]) && (
						<section className=" bg-bg">
							<Container fluid className="px-0 mx-0">
								<Row className=" g-0">
									<Col lg={{ span: 5, order: "last" }}>
										<GatsbyImage
											className="w-100 h-100"
											image={
												twoColumnHeadingSection?.image.node.localFile
													.childImageSharp.gatsbyImageData
											}
											alt={twoColumnHeadingSection?.image.node.altText}
										/>
									</Col>{" "}
									<Col
										className="py-5 px-3 px-lg-6 px-xl-8 py-lg-7 py-xxl-10"
										lg={7}
									>
										<h2 className="  fs-1 mb-5 ">
											{twoColumnHeadingSection?.heading}
										</h2>
										<SafeHtmlParser
											htmlContent={twoColumnHeadingSection?.content}
										/>
									</Col>
								</Row>
							</Container>
						</section>
					)}
				<ReviewSection
					noMargin
					heading={
						reviewsSection?.heading || "Reviews From Our Dentistry Patients"
					}
					smallheading={
						reviewsSection?.subHeading || "WHAT PEOPLE SAY ABOUT US"
					}
					page="Home"
				/>
				{homeContactSection &&
					!checkPropertiesForNull(homeContactSection, ["heading"]) && (
						<section className="py-5 py-lg-7">
							<Container>
								<Row>
									<Col lg={6} className="text-center text-lg-start">
										<h2 className=" text-center text-lg-start display-4 pb-4">
											<SafeHtmlParser
												htmlContent={homeContactSection?.heading}
											/>
										</h2>

										<SafeHtmlParser
											htmlContent={homeContactSection?.description}
										/>
									</Col>
									<Col className="" lg={6}>
										<ContactForm />
									</Col>
								</Row>
							</Container>
						</section>
					)}
				{/* <section className="">
					<Container>
						<Row>
							<Col className="text-center">
								<p className="text-accent jost-bold fs-6">TREATMENTS</p>
								<h2 className=" display-4 pb-4">Facial Aesthetics</h2>
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit
									ut tincidunt fringilla <br /> dictum senectus id.
								</p>
							</Col>
						</Row>
						<Row className="pt-8">
							<FacialCard
								title="Anti-wrinkle injections"
								img={wImg}
								imgAlt={data.wImg?.altText}
								text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque facilisis."
								url="/"
							/>
							<FacialCard
								title="Dermal fillers"
								img={wImg}
								imgAlt={data.wImg?.altText}
								text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque facilisis."
								url="/"
							/>
							<FacialCard
								title="Lip fillers"
								img={wImg}
								imgAlt={data.wImg?.altText}
								text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque facilisis."
								url="/"
							/>
							<FacialCard
								title="Tailored skincare"
								img={wImg}
								imgAlt={data.wImg?.altText}
								text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque facilisis."
								url="/"
							/>
						</Row>
					</Container>
				</section> */}
				{twoColumnLeftImageSection &&
					!checkPropertiesForNull(twoColumnLeftImageSection, ["heading"]) && (
						<section className="">
							{" "}
							<PhotoLeft
								imgHeight="40rem"
								img={
									twoColumnLeftImageSection.image.node?.localFile
										.childImageSharp.gatsbyImageData
								}
								imgAlt={twoColumnLeftImageSection.image.node?.altText}
								text={
									<div className="text-white">
										<h2 className=" text-center text-lg-start display-4 pb-4">
											{twoColumnLeftImageSection?.heading}
										</h2>
										<GatsbyImage
											className="mb-4 w-100 d-lg-none"
											image={
												twoColumnLeftImageSection.image.node?.localFile
													.childImageSharp.gatsbyImageData
											}
											alt={twoColumnLeftImageSection.image.node?.altText}
										/>
										<div className="content">
											<SafeHtmlParser
												htmlContent={twoColumnLeftImageSection?.content}
											/>
										</div>
									</div>
								}
								bgColour="bg-primary"
							/>
						</section>
					)}
			</Layout>
		</>
	);
};

export default IndexPage;
