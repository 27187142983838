import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "./link";
import Button from "react-bootstrap/Button";
import gsap from "gsap";
import { SafeHtmlParser } from "./safeHtmlParser";

const Hero = ({ image1, image2, title, subHeading, ctaButton, notice }) => {
  useEffect(() => {
    gsap.to(".main-image-left-hero", {
      y: 0,
      duration: 2,
      delay: 0.2,
    });
    gsap.to(".main-image-right-hero", {
      y: 0,
      duration: 2,
      delay: 0.2,
    });
  }, []);
  return (
    <>
      <section
        style={{ height: "71vh", overflow: "hidden" }}
        className="position-relative w-100"
      >
        <div
          className=" w-100 h-100 bg-primary position-absolute"
          style={{
            zIndex: 0,
            opacity: 0.7,
          }}
        ></div>
        <div
          style={{ zIndex: -1 }}
          className="h-100 position-absolute d-lg-none"
        >
          <GatsbyImage className="h-100 w-100" image={image1} alt="" />
        </div>
        <div
          style={{ zIndex: -1 }}
          className="h-100 position-absolute d-none d-lg-block start-0 main-image-left-hero"
        >
          <GatsbyImage className="h-100 w-100" image={image1} alt="" />
        </div>
        <div
          style={{ zIndex: -1 }}
          className="h-100 position-absolute  d-none d-lg-block end-0 main-image-right-hero"
        >
          <GatsbyImage className="h-100 w-100" image={image2} alt="" />
        </div>
        <Container style={{ zIndex: 1 }} className="position-relative h-100">
          <Row className="h-100 align-items-center">
            <Col>
              <p className="text-white text-center text-md-start jost-bold fs-6">
                {subHeading}
              </p>
              <h1 className="display-1 text-center text-md-start text-white">
                <SafeHtmlParser htmlContent={title} />
              </h1>
              {ctaButton && ctaButton.url && (
                <Row className="pt-4">
                  <Col>
                    <Button
                      variant="accent"
                      className=" py-2 fs-5 w-100 w-md-auto me-4 text-white px-5 "
                      href={ctaButton.url}
                      rel="noreferrer"
                      target={
                        ctaButton.target ??
                        "_blank"
                      }
                    >
                      {ctaButton.title}
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <div className="bg-secondary w-100 text-center  d-xl-flex align-items-center justify-content-center home-hero-bottom">
        <div className=" mb-0 py-2 py-xl-0 d-block">
          <SafeHtmlParser htmlContent={notice} />
        </div>
      </div>
    </>
  );
};

export default Hero;
