import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImg: wpMediaItem(title: { eq: "Image-Part-1-CU" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				reviews {
					reviewCount
				}
				schemaLogo: wpMediaItem(title: { eq: "dark-logo" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: TRACED_SVG
							)
						}
					}
				}
			}
		`
	);

	const reviewCount = data.reviews.reviewCount;
	const localBusinessSchema = {
		"@context": "https://schema.org",
		"@type": "LocalBusiness",
		name: "Dental House Exeter",
		legalName: "Dental House Exeter Limited",
		description:
			"Highest quality dental care in a warm and welcoming setting, offering the newest in digital dentistry",
		image:
			"https://www.dentalhouseexeter.co.uk/static/728cf95c27ebba9f9068b9e8ed3aabd5/bf51a/dark-logo.webp",
		"@id": "https://https:www.dentalhouseexeter.co.uk",
		slogan: "Quality dentistry from our warm and welcoming team",
		url: "https://www.dentalhouseexeter.co.uk",
		telephone: "01392873899",
		priceRange: "$$$",
		areaServed: [
			{
				"@type": "city",
				name: "Exeter",
				sameAs: "https://en.wikipedia.org/wiki/Exeter",
			},
		],
		email: "info@dentalhouseexeter.co.uk",
		currenciesAccepted: "GBP",
		numberOfEmployees: "7",
		location: {
			"@type": "City",
			name: "Exeter",
			sameAs: "https://en.wikipedia.org/wiki/Exeter",
		},
		foundingLocation: {
			"@type": "City",
			name: "Exeter",
			sameAs: "https://en.wikipedia.org/wiki/Exeter",
		},
		address: {
			"@type": "PostalAddress",
			streetAddress: "384 Topsham Rd",
			addressLocality: "Exeter",
			addressRegion: "Exeter",
			postalCode: "EX2 6HE",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: 50.702057843146235,
			longitude: -3.4946461851765056,
		},
		hasMap:
			"https://www.google.com/maps/place/Dental+House+Exeter/@50.7017704,-3.4980669,17z/data=!3m1!4b1!4m5!3m4!1s0x486da152bfe4a7b1:0xb04fc12e137f1846!8m2!3d50.7017794!4d-3.4958624?hl=en",
		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "https://schema.org/Monday",
				opens: "08:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "https://schema.org/Tuesday",
				opens: "08:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "https://schema.org/Wednesday",
				opens: "08:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "17:00",
				dayOfWeek: "https://schema.org/Thursday",
				opens: "08:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "13:00",
				dayOfWeek: "https://schema.org/Friday",
				opens: "08:00",
			},
		],
		founder: {
			"@type": "Person",
			name: "Shamik Desai",
			id: "https://www.dentalhouseexeter.co.uk/about#shamik-desai",
			url: "https://www.dentalhouseexeter.co.uk/about",
			description: "Managing Director",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			bestRating: "5",
			worstRating: "0",
			ratingValue: "4.9",
			reviewCount: "125",
			url: "https://www.dentalhouseexeter.co.uk",
		},
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://www.dentalhouseexeter.co.uk/contact-us",
			url: "https://www.dentalhouseexeter.co.uk/contact-us",
			image:
				"https://www.dentalhouseexeter.co.uk/static/8c34e64958314345f33c20b721dee4dd/d935d/1-scaled.webp",
			name: "Shamik Desai",
			description: "Contact point",
			telephone: "01392873899",
			contactType: "Contact center",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "Exeter",
					sameAs: "https://en.wikipedia.org/wiki/Exeter",
				},
			],
		},
		logo: "https://www.dentalhouseexeter.co.uk/static/dc9f49014d9d00f207ead172ffcc2d11/e2c03/White-on-Transparent.webp",
		sameAs: ["https://www.facebook.com", "https://www.instagram.com"],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
